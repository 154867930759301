import { useBuild } from "app/components/build/Show/lib/BuildContext";
import AnnotationsListRenderer from "../../components/AnnotationsListRenderer";

export default function Annotations() {
  const { build } = useBuild();

  return (
    <AnnotationsListRenderer
      params={{
        buildSlug: `${build.account.slug}/${build.project.slug}/${build.number}`,
      }}
      organization={build.account.slug}
      pipeline={build.project.slug}
      number={build.number}
      filterIssues={false}
      hasFailedJobs={false}
    />
  );
}
