import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { LoadingPanels } from "app/components/analytics/shared/LoadingStates";

/**
 * A container for the non-react flaky tests view.
 */

export default function TestDigestPage() {
  const { build } = useBuild();

  return (
    <turbo-frame
      class="flex flex-col w-full h-full relative"
      id="test_digest"
      src={`${build.path}/test_digest_tab`}
    >
      <div
        className={`${Features.BuildSidebar ? "w-full h-full absolute overflow-y-auto px-4 pt-4" : ""}`}
      >
        <LoadingPanels rows={10} columns={1} />
      </div>
    </turbo-frame>
  );
}
