import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { useBuildPreferencesStore } from "app/components/build/Show/lib/useBuildPreferencesStore";
import { useMemo } from "react";
import capitalize from "lodash/capitalize";
import { GroupStep, Step } from "app/lib/pipeline";
import EmptyState from "./EmptyState";
import { createStepListItem } from "./StepListItem";
import {
  groupStepsByState,
  filterSteps,
  useFilterStore,
  StateOrder,
  DisplayMode,
  useStateCounts,
  FilterByOption,
} from "./useFilterStore";
import { AnimatePresence, motion } from "framer-motion";
import * as Accordion from "@radix-ui/react-accordion";
import Icon from "app/components/shared/Icon";

// Create nested steps by grouping steps by their group UUID
function createNestedSteps(steps: Step[]) {
  const stepsMap = new Map(steps.map((step) => [step.uuid, { ...step }]));

  stepsMap.forEach((step) => {
    if (step.groupUuid) {
      const group = stepsMap.get(step.groupUuid) as GroupStep;
      if (group && step.type !== "group") {
        if (!group.steps) {
          group.steps = [];
        }
        group.steps.push(step);
      }
      stepsMap.delete(step.uuid);
    }
  });

  // Remove empty groups
  return Array.from(stepsMap.values()).filter((step) => {
    if (step.type === "group" && !step.steps?.length) {
      return false;
    }
    return true;
  });
}

interface StepListProps {
  /**
   * Force display mode to be used, regardless of the user's preference.
   */
  forceDisplayMode?: DisplayMode;

  /**
   * Filters to apply to the list of steps.
   */
  filters?: FilterByOption[];
}

/**
 * Render a list of build steps.
 */
export function StepList({ forceDisplayMode, filters = [] }: StepListProps) {
  const { build } = useBuild();

  const stateCounts = useStateCounts();
  const displayMode = useFilterStore((state) => forceDisplayMode ?? state.displayMode);

  const filteredSteps = useMemo(() => {
    const filtered = filterSteps(build.steps, filters, build);

    if (displayMode === DisplayMode.State) {
      return filtered.filter((step) => step.type !== "group");
    }

    return createNestedSteps(filtered);
  }, [build.steps, filters, build, displayMode]);

  const groupedSteps = useMemo(() => {
    if (displayMode !== DisplayMode.State) {
      return null;
    }
    return groupStepsByState(filteredSteps, build);
  }, [filteredSteps, displayMode]);

  const [expandedStates, setExpandedStates] = useBuildPreferencesStore((state) => [
    state.expandedStates,
    state.setExpandedStates,
  ]);

  if (filteredSteps.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className="p-1 md:p-0">
      {groupedSteps ? (
        <Accordion.Root
          type="multiple"
          defaultValue={[...StateOrder]}
          value={expandedStates}
          onValueChange={setExpandedStates}
          className="flex flex-col gap-1"
        >
          {StateOrder.map((state) => {
            const steps = groupedSteps[state];

            if (!steps) {
              return null;
            }

            return (
              <Accordion.Item key={state} value={state} data-testid={`${state}-group`}>
                <Accordion.Header className="flex-auto min-w-0 m-0">
                  <Accordion.Trigger className="flex w-full items-center py-1 pr-1 pl-2 group hover:bg-purple-100 rounded-md">
                    <span className="text-charcoal-600 font-normal uppercase tracking-wide text-xs/5">
                      {capitalize(state)}
                    </span>

                    <div className="shrink-0 flex items-center justify-center w-7">
                      <Icon
                        icon="heroicons/outline/chevron-right"
                        className="w-3 h-3 transition-transform duration-150 group-data-[state=open]:rotate-90"
                      />
                    </div>

                    <span className="ml-auto">
                      <span
                        className="text-xs font-medium px-1.5 py-0.5 bg-gray-100 rounded-sm group-hover:bg-inherit"
                        data-testid={`${state}-group-count`}
                      >
                        {stateCounts[state]}
                      </span>
                    </span>
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <AnimatePresence initial={false}>
                    {steps.map((step) => (
                      <motion.div
                        key={step.uuid}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                      >
                        {createStepListItem({ step })}
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </Accordion.Content>
              </Accordion.Item>
            );
          })}
        </Accordion.Root>
      ) : (
        <div className="flex flex-col">
          {filteredSteps.map((step) => (
            <div key={step.uuid}>{createStepListItem({ step })}</div>
          ))}
        </div>
      )}
    </div>
  );
}
