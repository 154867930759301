import BuildShowStore from "app/stores/BuildShowStore";
import { Build, Job, CommandJob } from "app/components/build/Show/lib/types";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { dropRight, filter, isNumber, matchesProperty, noop } from "lodash";

const BuildContext = createContext<NullableBuildContext>({
  build: null,
  store: null,
  setBuild: noop,
});

export const BuildContextProvider = ({ children, store }) => {
  const [build, setBuild] = useState(store?.build);

  useEffect(() => {
    store?.on("change", () => {
      setBuild(store?.build);
    });
    return () => {
      store?.off("change");
    };
  }, []);

  const loadAndEmit = useCallback(
    (build: Build) => {
      store.loadAndEmit(build);
    },
    [store],
  );

  return (
    <BuildContext.Provider value={{ build, setBuild: loadAndEmit, store }}>
      {children}
    </BuildContext.Provider>
  );
};

type StrictBuildContext = {
  build: Build;
  store: BuildShowStore;
  setBuild: (build: Build) => void;
};

type NullableBuildContext = {
  build: Build | null;
  store: BuildShowStore | null;
  setBuild: (build: Build) => void;
};

// Overload signatures
export function useBuild(): StrictBuildContext;
export function useBuild(options: { strict: false }): NullableBuildContext;

// Implementation
export function useBuild(options?: {
  strict?: boolean;
}): StrictBuildContext | NullableBuildContext {
  const context = useContext(BuildContext);

  if (context === undefined) {
    throw new Error("useBuild() must be used within a <BuildContextProvider />");
  }

  if (options?.strict !== false && (!context.build || !context.store)) {
    throw new Error("Build context is missing required build or store");
  }

  return context;
}

/**
 * Fetch the most recent parallel jobs for a given step.
 */
export const useMostRecentParallelJobsForStep = (stepUuid: string): CommandJob[] => {
  const jobs = useJobsForStep<CommandJob>(stepUuid);

  return mostRecentParallelJobs(jobs);
};

/**
 * Fetch the most recent matrix jobs for a given step.
 */
export const useMostRecentMatrixJobsForStep = (stepUuid: string): CommandJob[] => {
  const jobs = useJobsForStep<CommandJob>(stepUuid);

  return mostRecentMatrixJobs(jobs);
};

export function useJobsForStep<T extends Job>(stepUuid: string): T[] {
  const { store } = useBuild({ strict: false });

  if (!store) {
    return [];
  }
  return (store.stepJobs.get(stepUuid) as T[]) || [];
}

export function useMostRecentJob<T extends Job>(stepId: string): T | null {
  const jobs = useJobsForStep<T>(stepId);
  if (!jobs.length) {
    return null;
  }
  return jobs[jobs.length - 1];
}

export function findJobsForStep<T extends Job>(build: Build, stepUuid: string): T[] {
  return build.jobs.filter((job) => job.stepUuid === stepUuid) as T[];
}

export function mostRecentParallelJobs(jobs: CommandJob[]): CommandJob[] {
  return Array.from(
    jobs
      .reduce((acc, job) => {
        acc.set(job.parallelGroupIndex, job);
        return acc;
      }, new Map())
      .values(),
  );
}

export function mostRecentMatrixJobs(jobs: CommandJob[]): CommandJob[] {
  return Array.from(
    jobs
      .reduce((acc, job) => {
        if (!job.matrix) {
          return acc;
        }

        acc.set(JSON.stringify(job.matrix), job);
        return acc;
      }, new Map())
      .values(),
  );
}

export function useRetriesForJob(job: CommandJob, { includeLatest = true } = {}): CommandJob[] {
  let jobs = useJobsForStep<CommandJob>(job.stepUuid);

  if (job.matrix) {
    jobs = filter(jobs, matchesProperty("matrix", job.matrix));
  }

  if (isNumber(job.parallelGroupIndex)) {
    jobs = filter(jobs, { parallelGroupIndex: job.parallelGroupIndex });
  }

  if (!includeLatest) {
    return dropRight(jobs, 1);
  }

  return jobs;
}

export default BuildContext;
