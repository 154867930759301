import { useCallback, useMemo, useState } from "react";
import { CommandJob } from "app/components/build/Show/lib/types";
import { CollapsibleStepListItem } from "./CollapsibleStepListItem";
import { StepLink } from "./StepLink";
import { JobLabel } from "./JobLabel";
import {
  filterJobs,
  sortJobsByState,
  getRelevantSubFiltersForStep,
  isJobFailed,
} from "../useFilterStore";

import {
  useBuild,
  useJobsForStep,
  useMostRecentMatrixJobsForStep,
  useMostRecentParallelJobsForStep,
} from "app/components/build/Show/lib/BuildContext";
import {
  CommandStep,
  isDefaultCommandStep,
  isMatrixCommandStep,
  isParallelCommandStep,
} from "app/lib/pipeline";
import { resolveJobUrl, resolveStepUrl } from "app/components/build/Show/lib/urlForView";
import useCurrentJobOrStep from "app/components/build/Show/lib/useCurrentJobOrStep";
import { StepListItem } from "./StepListItem";
import classNames from "classnames";

interface Props {
  step: CommandStep;
  depth?: number;
}

/**
 * An command step list item.
 *
 * Rendered as a single list item unless there are multiple jobs for the step.
 */
export function CommandStepListItem({ step, ...props }: Props) {
  switch (true) {
    case isMatrixCommandStep(step):
      return <MatrixCommandStepListItem step={step} {...props} />;
    case isParallelCommandStep(step):
      return <ParallelCommandStepListItem step={step} {...props} />;
    case isDefaultCommandStep(step):
    default:
      return <DefaultCommandStepListItem step={step} {...props} />;
  }
}

function MatrixCommandStepListItem({ step, depth = 0 }: Props) {
  const { build } = useBuild();

  const { currentLatestJob } = useCurrentJobOrStep();
  const jobs = useMostRecentMatrixJobsForStep(step.uuid);
  const [showAllJobs, setShowAllJobs] = useState(false);

  const toggleShowAllJobs = useCallback(() => setShowAllJobs((prev) => !prev), []);

  const sortedJobs = useMemo(() => {
    const filtered = showAllJobs ? [...jobs] : filterStepJobs(jobs, step, build);

    filtered.sort(sortJobsByState("asc"));
    return filtered;
  }, [jobs, showAllJobs, step, build]);

  const hiddenCount = jobs.length - sortedJobs.length;

  return (
    <CollapsibleStepListItem step={step}>
      {sortedJobs.map((job) => (
        <StepLink
          key={job.id}
          type={step.type}
          to={resolveJobUrl(job.id)}
          active={job.id === currentLatestJob?.id}
          depth={depth + 1}
          failed={isJobFailed(job)}
        >
          <JobLabel job={job} step={step} />
        </StepLink>
      ))}

      {!showAllJobs && hiddenCount > 0 && (
        <ShowHiddenJobsButton hiddenCount={hiddenCount} onClick={toggleShowAllJobs} />
      )}
    </CollapsibleStepListItem>
  );
}

function ParallelCommandStepListItem({ step, depth = 0 }: Props) {
  const { build } = useBuild();

  const jobs = useMostRecentParallelJobsForStep(step.uuid);

  const { currentLatestJob } = useCurrentJobOrStep();
  const [showAllJobs, setShowAllJobs] = useState(false);

  const toggleShowAllJobs = useCallback(() => setShowAllJobs((prev) => !prev), []);

  const sortedJobs = useMemo(() => {
    const filtered = showAllJobs ? [...jobs] : filterStepJobs(jobs, step, build);

    filtered.sort(sortJobsByState("asc"));
    return filtered;
  }, [jobs, showAllJobs, step, build]);

  const hiddenCount = jobs.length - sortedJobs.length;

  return (
    <CollapsibleStepListItem step={step} depth={depth}>
      {sortedJobs.map((job) => (
        <StepLink
          key={job.id}
          type={step.type}
          to={resolveJobUrl(job.id)}
          active={job.id === currentLatestJob?.id}
          depth={depth + 1}
          failed={isJobFailed(job)}
        >
          <JobLabel job={job} step={step} />
        </StepLink>
      ))}

      {!showAllJobs && hiddenCount > 0 && (
        <ShowHiddenJobsButton
          hiddenCount={hiddenCount}
          onClick={toggleShowAllJobs}
          depth={depth + 1}
        />
      )}
    </CollapsibleStepListItem>
  );
}

function DefaultCommandStepListItem({ step, depth = 0 }: Props) {
  const { currentStep } = useCurrentJobOrStep();

  const jobs = useJobsForStep<CommandJob>(step.uuid);
  const job = jobs[jobs.length - 1];

  // FIXME: We should handle this better.
  if (!job) {
    return <StepListItem step={step} />;
  }

  return (
    <StepLink
      type={step.type}
      to={resolveStepUrl(step.uuid)}
      active={step.uuid === currentStep?.uuid}
      depth={depth}
      failed={isJobFailed(job)}
    >
      <JobLabel job={job} step={step} />
    </StepLink>
  );
}

const ShowHiddenJobsButton = ({ hiddenCount, onClick, depth = 0 }) => (
  <button
    onClick={onClick}
    className={classNames(
      "flex w-full text-xs/5 text-charcoal-300 px-1.5 hover:bg-purple-100/50-white rounded-md py-1 cursor-pointer text-left",
      {
        "pl-[1.75rem]": depth === 1,
        "pl-[3rem]": depth === 2,
      },
    )}
  >
    Show {hiddenCount} hidden {hiddenCount === 1 ? "job" : "jobs"}
  </button>
);

function filterStepJobs<T extends CommandJob>(jobs: T[], step, build): T[] {
  const filters = getRelevantSubFiltersForStep(step, build);
  return filterJobs(jobs, filters);
}
