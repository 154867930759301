import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { useMemo } from "react";
import useQueryParams from "./useQueryParams";
import { CommandJob, Job } from "./types";

/**
 * Hook for getting the current step or job from the URL based on either the step or job UUID.
 */
export default function useCurrentJobOrStep() {
  const { stepUuid, jobUuid } = useQueryParams();
  const { store } = useBuild();

  // Get the job based on the job UUID.
  const currentJob = useMemo(() => {
    return store.jobs.get(jobUuid || "");
  }, [jobUuid, store.jobs]);

  // Get the step based on the step UUID or the job's step UUID.
  const currentStep = useMemo(() => {
    return store.steps.get(currentJob?.stepUuid || stepUuid || "");
  }, [currentJob?.stepUuid, stepUuid, store.steps]);

  // Find the latest retry for the job (when applicable).
  const currentLatestJob = useMemo(() => {
    if (currentJob?.type === "script") {
      return findLatestRetry(currentJob, store.jobs);
    }
    return null;
  }, [currentJob, store?.jobs]);

  return {
    currentStep,
    currentJob,
    currentLatestJob,
  };
}

function findLatestRetry(job: CommandJob, jobs: Map<string, Job>) {
  if (job.retriedInJobUuid) {
    const retry = jobs.get(job.retriedInJobUuid);
    if (retry) {
      return findLatestRetry(retry as CommandJob, jobs);
    }
  }
  return job;
}
