import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { FrameElement } from "@hotwired/turbo";
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import Spinner from "app/components/shared/Spinner";

export default function StepUploads() {
  const { build } = useBuild();

  const ref = useRef<FrameElement>(null);

  const debouncedReload = debounce(() => {
    ref.current?.reload();
  }, 1000);

  useEffect(() => {
    debouncedReload();
  }, [build?.steps.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div data-turbo={true}>
      <turbo-frame id="step_uploads" src={`${build.path}/step-uploads`} ref={ref}>
        <div className="text-center p-3">
          <Spinner />
        </div>
      </turbo-frame>
    </div>
  );
}
