import { useBuild } from "app/components/build/Show/lib/BuildContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "app/components/shared/DropdownMenu";
import Icon from "app/components/shared/Icon";
import { twMerge } from "tailwind-merge";

export function PipelineSettings(props: { className?: string }) {
  const { build } = useBuild();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        title="Pipeline settings"
        className={twMerge(
          "flex items-center px-2 py-1.5 hover:bg-gray-200 rounded-md outline-none",
          props.className,
        )}
      >
        <Icon icon="down-triangle" className="flex-none w-2 h-2" />
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent>
          <DropdownMenuItem>
            <DropdownLink href={`${build.project.url}/settings`}>Settings</DropdownLink>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <DropdownLink href={`${build.project.url}/settings/steps`}>Edit steps</DropdownLink>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}

const DropdownLink = ({ href, children }) => (
  <a href={href} className="text-inherit focus:text-inherit hover:text-inherit grow">
    {children}
  </a>
);
