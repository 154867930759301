import { track } from "app/lib/segmentAnalytics";
import { useBuild } from "app/components/build/Show/lib/BuildContext";

export const useTrack = () => {
  const { build } = useBuild({ strict: false });

  // We should only perform tracking if we have a build.
  // This will avoid tracking in public pages such as the playground.
  if (!build) {
    return () => null;
  }

  const buildContext = {
    build_number: build.number,
    organization_slug: build.account.slug,
    pipeline: build.project.slug,
  };

  return (event: string, properties?: Record<string, any>) => {
    track(event, Object.assign(buildContext, properties));
  };
};
