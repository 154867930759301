import { Children, PropsWithChildren, useCallback, useEffect, useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import Icon from "app/components/shared/Icon";
import { isFailed, Outcome, State, Step } from "app/lib/pipeline";
import { StepListItem } from "./StepListItem";
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import useCurrentJobOrStep from "app/components/build/Show//lib/useCurrentJobOrStep";
import { twMerge } from "tailwind-merge";

interface Props {
  step: Step;
  depth?: number;
}

export function CollapsibleStepListItem({ step, children, depth = 0 }: PropsWithChildren<Props>) {
  const { currentStep, currentJob } = useCurrentJobOrStep();

  // If the step is hard failed or failing, default to open
  const [value, setValue] = useState<string[]>(
    (step.state === State.Finished && step.outcome === Outcome.HardFailed) ||
      step.state === State.Failing
      ? [step.uuid]
      : [],
  );

  // If the step transitions to hard failed or failing, open the step
  useEffect(() => {
    if (
      (step.state === State.Finished && step.outcome === Outcome.HardFailed) ||
      step.state === State.Failing
    ) {
      setValue([step.uuid]);
    }
  }, [step.state]);

  useEffect(() => {
    const job = currentJob;
    const stepUuid = currentStep?.uuid;

    if (step.type === "group") {
      const groupStepIds = step.steps?.map((step) => step.uuid) || [];

      // step within group step is clicked
      if (stepUuid && groupStepIds.includes(stepUuid)) {
        setValue([step.uuid]);
      }

      // job within group step is clicked
      if (job && job.groupUuid === step.uuid) {
        setValue([step.uuid]);
      }
    }

    if (job && step.uuid === job.stepUuid) {
      setValue([step.uuid]);
    }
  }, [currentJob?.id, currentStep?.uuid]);

  const onValueChange = useCallback((value: string[]) => {
    setValue(value);
  }, []);

  const open = useCallback(() => {
    setValue([step.uuid]);
  }, []);

  const active = !currentJob && step.uuid === currentStep?.uuid;

  if (!Children.count(children)) {
    return <StepListItem step={step} active={active} />;
  }

  return (
    <Accordion.Root
      type="multiple"
      className="flex flex-auto min-w-0"
      onValueChange={onValueChange}
      value={value}
    >
      <Accordion.Item value={step.uuid} className="w-full">
        <div>
          <Accordion.Trigger
            tabIndex={-1}
            className={twMerge(
              "flex w-full items-baseline rounded-md [&>div>svg]:data-[state=open]:rotate-90 relative group",
            )}
          >
            <div className="flex flex-auto min-w-0">
              <StepListItem
                step={step}
                onClick={open}
                active={active}
                // Highlight step as failed only when it's failed and the group is collapsed.
                failed={isFailed(step) && !value.length}
                className={classNames("group-hover:bg-purple-100/50-white", {
                  "pl-[calc(1.5rem_+_2px)]": depth === 0, // + 2px to account for chevron padding
                  "pl-[calc(2.5rem_+_4px)]": depth === 1, // + 4px to account for chevron padding
                })}
              />
            </div>

            <div
              tabIndex={-1}
              className={twMerge(
                classNames(
                  "flex items-center justify-center w-6 hover:hover:bg-[#CDC6FF] rounded-sm absolute top-[2px] left-[2px] bottom-[2px]",
                  {
                    "ml-[calc(1rem_+_2px)]": depth === 1,
                  },
                ),
              )}
            >
              <Icon
                icon="heroicons/outline/chevron-right"
                className="w-3 h-3 transition-transform duration-150"
              />
            </div>
          </Accordion.Trigger>
        </div>
        <div className="overflow-y-clip">
          <Accordion.Content className="data-[state=open]:animate-[radixAccordionSlideDown_150ms_ease-in-out] data-[state=closed]:animate-[radixAccordionSlideUp_150ms_ease-in-out] relative">
            <div
              className={classNames("border-l border-gray-400 absolute top-0 bottom-0", {
                "left-[calc(0.75rem+1px)]": depth === 0,
                "left-8": depth === 1,
              })}
            />
            <div className="relative">{children}</div>
          </Accordion.Content>
        </div>
      </Accordion.Item>
    </Accordion.Root>
  );
}
