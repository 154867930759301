import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import groupBy from "lodash/groupBy";
import {
  Step,
  State as StepState,
  isCanceled,
  isFailed,
  isPassed,
  isRunning,
  isScheduled,
  isSoftFailed,
  isWaiting,
} from "app/lib/pipeline";
import { JobStates, Job, CommandJob, TriggerJob } from "app/components/build/Show/lib/types";
import { BuildStates, isTerminalBuildState } from "app/constants/BuildStates";
import { SortFn } from "../TableView/TableCell";
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { useMemo } from "react";

export enum DisplayMode {
  State = "state",
  Pipeline = "pipeline",
}

const DEFAULT_DISPLAY_MODE = DisplayMode.State;

export enum FilterByOption {
  Failed = "failed",
  Passed = "passed",
  Running = "running",
  Scheduled = "scheduled",
  SoftFailed = "soft-failed",
  Waiting = "waiting",
  Canceled = "canceled",
}

export const StateOrder = [
  FilterByOption.Failed,
  FilterByOption.Canceled,
  FilterByOption.Running,
  FilterByOption.Scheduled,
  FilterByOption.Waiting,
  FilterByOption.SoftFailed,
  FilterByOption.Passed,
];

// Essentially the same as JobStates except with the finished state resolved to either: passed, hard_failed, and soft_failed
// to allow for sorting by state.
export type ResolvedJobState =
  | Exclude<JobStates, "finished">
  | "hard_failed"
  | "soft_failed"
  | "passed";

export const JobStateOrder: ResolvedJobState[] = [
  // Failed
  "hard_failed",
  "canceled",
  "canceling",
  "timed_out",
  "expired",

  // Running
  "running",

  // Scheduled
  "accepted",
  "assigned",
  "scheduled",
  "limited",
  "limiting",

  // Waiting
  "waiting",
  "blocked",

  // Soft failed
  "soft_failed",

  // Passed
  "passed",
  "unblocked",

  // Initial states (pending, skipped, broken)
  "pending",
  "skipped",
  "broken",
];

/**
 * Sort jobs by state order
 * @param a
 * @param b
 * @returns sorted jobs
 */
// eslint-disable-next-line id-length
export const sortJobsByState: SortFn = (direction) => (a, b) => {
  const aState = resolveJobState(a as CommandJob | TriggerJob);
  const bState = resolveJobState(b as CommandJob | TriggerJob);
  const aIndex = JobStateOrder.indexOf(aState);
  const bIndex = JobStateOrder.indexOf(bState);

  if (aIndex === bIndex) {
    return 0;
  }

  if (direction === "asc") {
    return aIndex - bIndex;
  }

  return bIndex - aIndex;
};

export function resolveJobState(job: CommandJob | TriggerJob): ResolvedJobState {
  if (job.state === "finished") {
    if (!job.passed) {
      return "hard_failed";
    }
    if (job.softFailed) {
      return "soft_failed";
    }
    return "passed";
  }
  return job.state;
}

interface FilterState {
  displayMode: DisplayMode | null;
  setDisplayMode: (displayMode: DisplayMode | null) => void;

  filterBy: FilterByOption[];
  setFilterBy: (filters: FilterByOption[]) => void;
  resetFilters: () => void;
  resetAll: () => void;
}

export const useFilterStore = create<FilterState>()(
  persist(
    (set) => ({
      displayMode: DEFAULT_DISPLAY_MODE,
      filterBy: [],
      setDisplayMode: (displayMode) => set({ displayMode: displayMode }),
      setFilterBy: (filterBy) => set({ filterBy }),
      resetAll: () => set({ filterBy: [], displayMode: null }),
      resetFilters: () => {
        set({ filterBy: [] });
      },
    }),
    {
      name: "build-filters",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ displayMode: state.displayMode }),
      version: 2,
    },
  ),
);

type Build = {
  state: BuildStates;
};

/**
 * Get relevant job filters for a given step based on the current step and build state.
 *
 * This is used to determine which jobs should be shown for matrix and parallel steps when
 * the job state is not the same as the step state.
 */
export const getRelevantSubFiltersForStep = (step: Step, build: Build) => {
  const filter = getRelevantFilterForStep(step, build);
  if (!filter) {
    return [];
  }

  const idx = StateOrder.indexOf(filter);
  const filters = StateOrder.slice(0, idx + 1);

  return filters;
};

/**
 * Get the relevant filter for a given step based on the step state and build state.
 *
 * In some ways this is just flattening out the state into something more manageable.
 */
const getRelevantFilterForStep = (step: Step, build: Build) => {
  if (isTerminalBuildState(build.state) && step.missingDependencies?.length) {
    return FilterByOption.Failed;
  }
  if (isFailed(step)) {
    return FilterByOption.Failed;
  }
  if (isPassed(step)) {
    return FilterByOption.Passed;
  }
  if (isRunning(step)) {
    return FilterByOption.Running;
  }
  if (isScheduled(step)) {
    return FilterByOption.Scheduled;
  }
  if (isSoftFailed(step)) {
    return FilterByOption.SoftFailed;
  }
  if (isWaiting(step)) {
    return FilterByOption.Waiting;
  }
  if (isCanceled(step)) {
    return FilterByOption.Canceled;
  }
  return null;
};

const STEP_FILTER_FUNCTIONS: Record<FilterByOption, (step: Step, build: Build) => boolean> = {
  // A step is considered failed if it's failing or failed or has missing dependencies (if the build is terminal).
  [FilterByOption.Failed]: function (step: Step, build: Build) {
    if (isTerminalBuildState(build.state) && Boolean(step?.missingDependencies?.length)) {
      return true;
    }

    return isFailed(step);
  },
  [FilterByOption.Passed]: function (step: Step) {
    return isPassed(step);
  },
  [FilterByOption.Running]: function (step: Step) {
    return isRunning(step);
  },
  [FilterByOption.Scheduled]: function (step: Step) {
    return isScheduled(step);
  },
  [FilterByOption.SoftFailed]: function (step: Step) {
    return isSoftFailed(step);
  },
  [FilterByOption.Waiting]: function (step: Step) {
    return isWaiting(step);
  },
  [FilterByOption.Canceled]: function (step: Step) {
    return isCanceled(step);
  },
};

export function isJobFailed(job: Job) {
  return (
    (job.type === "script" && job.state === "finished" && !job.passed) ||
    job.state === "canceled" ||
    job.state === "timed_out"
  );
}

const JOB_FILTER_FUNCTIONS: Record<FilterByOption, (job: Job) => boolean> = {
  [FilterByOption.Failed]: function (job: Job) {
    return (
      (job.type === "script" && job.state === "finished" && !job.passed) ||
      job.state === "canceled" ||
      job.state === "timed_out"
    );
  },
  [FilterByOption.Passed]: function (job: Job) {
    if (job.type === "script" || job.type === "trigger") {
      return job.state === "finished" && Boolean(job.passed);
    }
    return job.state === "finished";
  },
  [FilterByOption.Running]: function (job: Job) {
    return job.state === "running";
  },
  [FilterByOption.Scheduled]: function (job: Job) {
    return job.state === "scheduled";
  },
  [FilterByOption.SoftFailed]: function (job: Job) {
    return Boolean(job.softFailed);
  },
  [FilterByOption.Waiting]: function (job: Job) {
    return job.state === "waiting";
  },
  [FilterByOption.Canceled]: function (job: Job) {
    return job.state === "canceled";
  },
};

export function filterSteps(steps: Step[], filterOptions: FilterByOption[], build: Build): Step[] {
  // Remove ignored steps from the list
  steps = steps.filter((step) => step.state !== StepState.Ignored);

  if (filterOptions.length < 1) {
    // Collapse consecutive wait steps
    return steps.reduce<Step[]>((acc, step) => {
      const prevStep = acc[acc.length - 1];
      if (prevStep?.type === "wait" && step.type === "wait") {
        return acc;
      }
      return acc.concat(step);
    }, []);
  }

  const filters = filterOptions.map((filter) => STEP_FILTER_FUNCTIONS[filter]);
  return steps.filter((step) => {
    // Remove wait steps from the list
    if (step.type === "wait") {
      return false;
    }

    // Apply all filters using logical OR operation
    return filters.some((filter) => filter(step, build));
  });
}

export function filterJobs<TJob extends Job>(jobs: TJob[], filters: FilterByOption[]): TJob[] {
  if (filters.length < 1) {
    return jobs;
  }

  return jobs.filter((job) => {
    // Apply all filters using logical OR operation
    return filters.some((filter) => {
      return JOB_FILTER_FUNCTIONS[filter](job);
    });
  });
}

export function groupStepsByState(steps: Step[], build: Build) {
  return groupBy(steps, (step) => {
    // Remove wait steps from the list
    if (step.type === "wait") {
      return false;
    }

    return getRelevantFilterForStep(step, build);
  });
}

export const useStateGroupedSteps = () => {
  const { build } = useBuild();

  return useMemo(() => {
    const steps = build?.steps.filter((step) => step.type !== "group") || [];
    return groupStepsByState(steps, build);
  }, [build.steps]);
};

export const useStateCounts = () => {
  const steps = useStateGroupedSteps();

  return useMemo(() => {
    return Object.fromEntries(StateOrder.map((state) => [state, steps?.[state]?.length || 0]));
  }, [steps]);
};
