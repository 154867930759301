import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { getBuildColor } from "../lib/getBuildColor";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

export const StatusBar = ({ className }: Props) => {
  const { build } = useBuild();

  const isRunning =
    build.state === "failing" ||
    build.state === "started" ||
    build.state === "canceling" ||
    (build.state === "blocked" && build.blockedState === "running");

  const runningStyles = isRunning
    ? {
        animation: "animation-running-bar_horizontal 1s infinite linear",
        backgroundImage:
          "linear-gradient(-45deg, rgba(255,255,255,.5) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.5) 50%, rgba(255,255,255,.5) 75%, transparent 75%, transparent)",
        backgroundSize: "12px 12px",
      }
    : {};

  return (
    <div
      className={twMerge("h-1", className)}
      style={{
        backgroundColor: getBuildColor(build).primaryColor,
        ...runningStyles,
      }}
    />
  );
};
