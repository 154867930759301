import React, { useCallback, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { useTrack } from "app/components/build/Show/lib/useTrack";
import classNames from "classnames";

interface Props {
  to: string;
  type?: string;
  children: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  active?: boolean;
  failed?: boolean;
  depth?: number;
}

/**
 * A themed sidebar link that is scrolled into view when active.
 */
export const StepLink = ({
  to,
  type,
  className,
  children,
  onClick,
  active,
  depth,
  failed,
}: Props) => {
  const track = useTrack();
  const scrollRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (active) {
      // Delay to ensure a nested group step is expanded before scrolling into view
      setTimeout(() => {
        const stepEl = scrollRef.current;

        if (stepEl === null) {
          return;
        }

        // Only scroll step into view if it is not visible in the viewport
        if (
          stepEl.getBoundingClientRect().bottom > window.innerHeight ||
          stepEl.getBoundingClientRect().top < 0
        ) {
          stepEl.scrollIntoView({ block: "center" });
        }
      }, 250);
    }
  }, [active]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      track("Build Sidebar Step Clicked", { type });

      onClick?.(event);

      if (active && !event.metaKey) {
        event.preventDefault();
        navigate(pathname);
        return;
      }
    },
    [active],
  );

  return (
    <Link
      state={{ forceFocus: true }}
      aria-current={active ? "step" : undefined}
      onClick={handleClick}
      to={to}
      ref={scrollRef}
      className={twMerge(
        "px-2 text-sm flex flex-auto min-w-0 gap-1 items-baseline text-charcoal-700 no-underline hover:no-underline active:no-underline focus:no-underline rounded-md hover:text-inherit focus:text-inherit",
        // Blended color to have the appearance of purple-100 with opacity 50%
        "hover:bg-[#e3dfff80] aria-[current=step]:bg-purple-100/50-white",
        classNames({
          // Blended color to have the appearance of red-100 with opacity 50%
          "[&:not([aria-current=step])]:bg-red-100/50-white [&:not([aria-current=step])]:hover:bg-purple-100/50-white ":
            failed,

          // Group appearance of consecutive failed links by removing round borders, keep the active job rounded.
          "failed [&.failed]:rounded-none [&.failed:first-child]:rounded-t-md [&.failed:not(:has(+_.failed)):not(:has(~_.failed_+_:not(.failed)))]:rounded-b-md aria-[current=step]:!rounded-md":
            failed,

          // Active appearance
          "ring-2 ring-purple-600 ring-inset relative": active,

          // Indentation based on depth
          "pl-7": depth === 1,
          "pl-12": depth === 2,
        }),
        className,
      )}
    >
      {children}
    </Link>
  );
};
