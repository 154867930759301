import { DateRange, DayPicker } from "react-day-picker";
import { UsagePeriod, formatDatePeriod } from "../helper";

import moment from "moment";
import { useState } from "react";

const formatDate = (date: Date) => moment(date).format("YYYY-MM-DD");

const DropdownContent = ({ recentSubscriptions }: { recentSubscriptions: Array<UsagePeriod> }) => {
  const [current, setCurrent] = useState<DateRange | undefined>();
  const onPeriodClicked = (period: UsagePeriod) => {
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set("from", period.start_date);
    queryParams.set("to", period.end_date);
    window.location.search = queryParams.toString();
  };

  // Only render 5 subscriptions at most as the UI contains a calendar too!.
  const periodsToRender = recentSubscriptions.slice(0, 5);

  return (
    <div className="px-4 py-2 flex flex-row divide-x divide-gray-500">
      <DayPicker
        mode="range"
        selected={current}
        onSelect={(range) => {
          setCurrent(range);
          // Redirect to the new range once they've selected the range
          if (range?.from && range?.to) {
            onPeriodClicked({
              start_date: formatDate(range.from),
              end_date: formatDate(range.to),
            });
          }
        }}
      />
      <div className="w-full">
        <p className="font-medium text-base mt-0">Recent periods</p>
        <ul className="w-full">
          {periodsToRender.map((subscriptionPeriod) => (
            <li key={subscriptionPeriod.start_date}>
              <button
                className="m-0 py-2 bg-white border-none text-left hover-purple cursor-pointer button w-full text-sm"
                onClick={() => onPeriodClicked(subscriptionPeriod)}
              >
                {formatDatePeriod(subscriptionPeriod)}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownContent;
