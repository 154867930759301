/* eslint-disable react/jsx-no-bind */
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { ErrorBoundary } from "app/lib/Bugsnag";
import { ErrorView } from "app/components/shared/ErrorView";
import { useNavigate } from "react-router-dom";
import { TableView } from "../components/TableView";

export default function TablePage() {
  const navigate = useNavigate();

  return (
    <ErrorBoundary
      FallbackComponent={() => <ErrorView className="px-4 py-2" clearError={() => navigate(0)} />}
    >
      <div className="flex-auto min-w-0 relative" data-testid="table">
        <div className="flex w-full h-full absolute">
          <TableView />
        </div>
      </div>
    </ErrorBoundary>
  );
}
