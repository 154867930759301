/* eslint-disable id-length */
/* eslint-disable react/jsx-no-bind */
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { Job } from "app/components/build/Show/lib/types";
import * as TableCell from "./TableCell";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { useBuildPreferencesStore } from "../../lib/useBuildPreferencesStore";
import Icon from "app/components/shared/Icon";
import { filterJobs, useFilterStore } from "../Sidebar/useFilterStore";
import { useMemo, useRef } from "react";
import { resolveJobUrl, resolveStepUrl } from "../../lib/urlForView";
import useQueryParams from "../../lib/useQueryParams";
import {
  useTableVirtualizer,
  VirtualizedTable,
  VirtualizedTableSpacer,
} from "app/components/shared/VirtualizedTable";

interface Column {
  key: string;
  label: string;
  component: (props: { job: Job }) => React.ReactNode;
  sortFn?: TableCell.SortFn;
  className?: string;
}

const columns: Column[] = [
  {
    key: "state",
    label: "State",
    component: TableCell.State,
    className: "w-[64px]",
    sortFn: TableCell.sortByState,
  },
  {
    key: "label",
    label: "Label",
    component: TableCell.Label,
    className: "w-[20%]",
    sortFn: TableCell.sortByLabel,
  },
  {
    key: "command",
    label: "Command",
    component: TableCell.Command,
    className: "w-[15%]",
    sortFn: TableCell.sortByCommand,
  },
  {
    key: "queue",
    label: "Queue",
    component: TableCell.Queue,
    className: "w-[15%]",
    sortFn: TableCell.sortByQueue,
  },
  {
    key: "agent",
    label: "Agent",
    component: TableCell.Agent,
    className: "w-[15%]",
    sortFn: TableCell.sortByAgent,
  },
  {
    key: "wait_duration",
    label: "Wait time",
    component: TableCell.WaitDuration,
    className: "w-[10%]",
    sortFn: TableCell.sortByWaitDuration,
  },
  {
    key: "run_duration",
    label: "Run time",
    component: TableCell.RunDuration,
    className: "w-[10%]",
    sortFn: TableCell.sortByRunDuration,
  },
  {
    key: "start_time",
    label: "Started at",
    component: TableCell.StartTime,
    className: "w-[15%]",
    sortFn: TableCell.sortByStartTime,
  },
];

const useTableSorting = () => {
  const sortDirection = useBuildPreferencesStore((state) => state.sortDirection);
  const setSortDirection = useBuildPreferencesStore((state) => state.setSortDirection);

  const sortColumn = useBuildPreferencesStore((state) => state.sortColumn);
  const setSortColumn = useBuildPreferencesStore((state) => state.setSortColumn);

  const clickCountRef = useRef(0);

  const setSort = (column: string) => {
    if (column === sortColumn) {
      clickCountRef.current += 1;

      // Reset sorting if the same column is clicked twice
      if (clickCountRef.current > 1) {
        setSortColumn(null);
        setSortDirection(null);
        clickCountRef.current = 0;
      } else {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      }
    } else {
      clickCountRef.current = 0;
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  return { sortColumn, sortDirection, setSort };
};

export function TableView() {
  const { build } = useBuild();

  const { sortColumn, sortDirection, setSort } = useTableSorting();
  const filter = useFilterStore((state) => state.filterBy);
  const params = useQueryParams();

  const jobs = useMemo(() => {
    // Filter to executable jobs
    let jobs =
      build?.jobs.filter(
        (job) => job.state !== "broken" && (job.type === "script" || job.type === "trigger"),
      ) || [];

    // Filter jobs based on the current filter
    jobs = filterJobs(jobs, filter);

    // Sort jobs by the current sort column/direction
    const column = columns.find((c) => c.key === sortColumn);
    if (column?.sortFn && sortDirection) {
      jobs.sort(column.sortFn(sortDirection));
    }

    return jobs;
  }, [build, filter, sortColumn, sortDirection]);

  const parentRef = useRef(null);

  const { virtualizer, virtualItems, heightBeforeItems, heightAfterItems } = useTableVirtualizer({
    items: jobs,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 38,
    overscan: 20,
  });

  return (
    <VirtualizedTable ref={parentRef} virtualizer={virtualizer} data-testid="build-table">
      <table className="w-full min-w-[1000px] border-separate table-fixed">
        <thead className="sticky top-0 z-10">
          <tr>
            {columns.map((column) => (
              <th
                onClick={column.sortFn ? () => setSort(column.key) : undefined}
                key={column.key}
                className={classNames(
                  "px-2.5 py-2 first:pl-4 last:pr-4 bg-white/90 backdrop-blur border-b border-gray-400",
                  {
                    "hover:underline cursor-pointer select-none": column.sortFn,
                    underline: column.key === sortColumn,
                  },
                  column.className,
                )}
              >
                <span className="flex items-center font-medium">
                  <span className="truncate max-w-full">{column.label}</span>

                  {column.key === sortColumn && (
                    <Icon
                      icon={
                        sortDirection === "desc"
                          ? "heroicons/outline/arrow-down"
                          : "heroicons/outline/arrow-up"
                      }
                      className="w-2 h-2 ml-1 -mr-3"
                    />
                  )}
                </span>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <VirtualizedTableSpacer height={heightBeforeItems} colSpan={columns.length} />

          {virtualItems.map((virtualItem) => {
            const job = jobs[virtualItem.index];

            return (
              <Link
                key={job.id}
                id={`job-${job.id}`}
                to={job.type === "trigger" ? resolveStepUrl(job.stepUuid) : resolveJobUrl(job.id)}
                className={twMerge(
                  "select-text text-inherit table-row",
                  "hover:text-inherit hover:no-underline hover:bg-gray-100",
                  "focus:text-inherit focus:no-underline",
                  "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-opacity-50",
                  classNames({
                    "bg-gray-100": params.jobUuid === job.id || params.stepUuid === job.stepUuid,
                  }),
                )}
              >
                {columns.map((column) => (
                  <td
                    className="py-2 px-2.5 first:pl-4 last:pr-4 truncate max-w-full align-middle border-b border-gray-400"
                    key={column.key}
                  >
                    {column.component({ job }) || <span className="text-gray-800">—</span>}
                  </td>
                ))}
              </Link>
            );
          })}

          <VirtualizedTableSpacer height={heightAfterItems} colSpan={columns.length} />
        </tbody>
      </table>
    </VirtualizedTable>
  );
}
