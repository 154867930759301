import { useBuild } from "app/components/build/Show/lib/BuildContext";
import V2TestEngine from "app/components/build/Show/V2TestEngine";

export default function Tests() {
  const { build } = useBuild();
  const buildSidebarDisabled = !Features.BuildSidebar;

  return (
    <div className={`flex-auto min-w-0 relative${buildSidebarDisabled ? " -mt-3" : ""}`}>
      <div className="h-full w-full absolute overflow-y-auto">
        <V2TestEngine build={build} />
      </div>
    </div>
  );
}
