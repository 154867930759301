/* eslint-disable react/no-unused-prop-types */
import BuildShowStore from "app/stores/BuildShowStore";
import { Build } from "app/components/build/Show/lib/types";
import { BuildContextProvider, useBuild } from "app/components/build/Show/lib/BuildContext";
import {
  RouterProvider,
  Navigate,
  RouteObject,
  useNavigate,
  createBrowserRouter,
  NonIndexRouteObject,
  useRouteError,
  useParams,
  isRouteErrorResponse,
} from "react-router-dom";

import { ErrorView } from "app/components/shared/ErrorView";
import BuildShowLayout from "./layouts/BuildShowLayout";
import JobsPage from "./pages";
import CanvasPage from "./pages/canvas";
import SummaryPage from "./pages/summary";
import StepsPage from "./pages/steps";
import WaterfallPage from "./pages/waterfall";
import TestDigestPage from "./pages/test-digest";
import TestsPage from "./pages/tests";
import { parseHash } from "app/stores/BuildPageStore";
import Annotations from "./pages/summary/annotations";
import { useEffect } from "react";
import Bugsnag from "app/lib/Bugsnag";
import { isTerminalBuildState } from "app/constants/BuildStates";
import { FaviconPaths } from "app/lib/Favicon";
import StepUploads from "./pages/summary/step-uploads";
import { urlForView } from "./lib/urlForView";
import { StepsDisplayMode, useBuildPreferencesStore } from "./lib/useBuildPreferencesStore";
import useQueryParams from "./lib/useQueryParams";

export enum BuildView {
  Steps = "STEPS",
  Summary = "SUMMARY",
  JobList = "JOB_LIST",
  Waterfall = "WATERFALL",
  Table = "TABLE",
  Canvas = "CANVAS",
  TestDigest = "TEST_DIGEST",
  Tests = "TESTS",
}

const waterfallRoute: NonIndexRouteObject = {
  path: "waterfall",
  id: BuildView.Waterfall,
  element: <WaterfallPage />,
  errorElement: <RouteError />,
};

/**
 * Handle redirecting if the user navigates to the base route
 * i.e. `/:org/:pipeline/builds/:number/`
 */
function BaseRouteRedirect() {
  const { build } = useBuild();

  let url = urlForView(build.path, BuildView.Steps);

  // If the URL has a hash, redirect to the appropriate canvas job.
  const hash = parseHash(window.location.hash);
  if (hash && hash.job) {
    url += `?jid=${hash.job}`;

    // Preserve the line number if it exists.
    if (hash.line && hash.group) {
      url += `#/${hash.group}-${hash.line}`;
    } else if (hash.group) {
      url += `#/${hash.group}`;
    }
    // If no hash exists, and the build is terminal:
  } else if (!hash && build && isTerminalBuildState(build.state)) {
    const annotationCount = Object.values(build.annotationCountsByStyle).reduce(
      (acc, count) => acc + count,
      0,
    );

    if (annotationCount > 0) {
      url = urlForView(build.path, BuildView.Summary);
    }
  }

  return <Navigate to={url} replace={true} />;
}

function CatchAllRoute() {
  const queryParams = useQueryParams();
  const { org, pipeline, number, ...rest } = useParams();
  const setDisplayMode = useBuildPreferencesStore((state) => state.setStepsDisplayMode);

  const basePath = `/${org}/${pipeline}/builds/${number}`;
  let redirectPath;

  switch (rest["*"]) {
    case "waterfall":
      setDisplayMode(StepsDisplayMode.Waterfall);
      redirectPath = urlForView(basePath, BuildView.Steps, queryParams);
      break;
    case "canvas":
      setDisplayMode(StepsDisplayMode.Canvas);
      redirectPath = urlForView(basePath, BuildView.Steps, queryParams);
      break;
    case "table":
      setDisplayMode(StepsDisplayMode.Table);
      redirectPath = urlForView(basePath, BuildView.Steps, queryParams);
      break;
    default:
      redirectPath = basePath;
      break;
  }

  // Rather than using '.' we create the new URL to ensure the correct hash is preserved
  redirectPath = `${redirectPath}${window.location.hash}`;

  return <Navigate to={redirectPath} replace={true} />;
}

export const routes: RouteObject[] = Features.BuildSidebar
  ? [
      {
        path: ":org/:pipeline/builds/:number/",
        element: <BuildShowLayout />,
        errorElement: <RouteError />,
        children: [
          {
            index: true,
            element: <BaseRouteRedirect />,
            errorElement: <RouteError />,
          },
          {
            path: "summary",
            id: BuildView.Summary,
            element: <SummaryPage />,
            errorElement: <RouteError />,
            children: [
              {
                index: true,
                element: <Navigate to="./annotations" replace={true} />,
              },
              {
                path: "annotations",
                element: <Annotations />,
                errorElement: <RouteError />,
              },
              {
                path: "step-uploads",
                element: <StepUploads />,
                errorElement: <RouteError />,
              },
            ],
          },
          {
            path: "steps",
            id: BuildView.Steps,
            element: <StepsPage />,
            errorElement: <RouteError />,
          },
          {
            path: "test-digest",
            id: BuildView.TestDigest,
            element: <TestDigestPage />,
            errorElement: <RouteError />,
          },
          {
            path: "tests",
            id: BuildView.Tests,
            element: <TestsPage />,
            errorElement: <RouteError />,
          },
          {
            // Catch all unknown paths and redirect to the build root
            path: "*",
            element: <CatchAllRoute />,
          },
        ],
      },
    ]
  : [
      {
        path: ":org/:pipeline/builds/:number/",
        element: <BuildShowLayout />,
        errorElement: <RouteError />,
        children: [
          {
            index: true,
            id: BuildView.JobList,
            element: <JobsPage />,
            errorElement: <RouteError />,
          },
          {
            path: "canvas",
            id: BuildView.Canvas,
            element: <CanvasPage />,
            errorElement: <RouteError />,
          },
          waterfallRoute,
          {
            path: "test-digest",
            id: BuildView.TestDigest,
            element: <TestDigestPage />,
            errorElement: <RouteError />,
          },
          {
            path: "tests",
            id: BuildView.Tests,
            element: <TestsPage />,
            errorElement: <RouteError />,
          },
          {
            path: "*", // Catch all unknown paths and redirect to the job list
            element: <CatchAllRoute />,
          },
        ],
      },
    ];

function RouteError() {
  const navigate = useNavigate();
  const routeError = useRouteError();

  // A 404 (Not Found) can occur when navigating to a non-existent route that's been pushed into history
  // by another client-side history action. (ie. turbo links)
  const is404 = isRouteErrorResponse(routeError) && routeError.status === 404;

  useEffect(() => {
    // If the error is a 404, don't report it to Bugsnag.
    if (is404) {
      return;
    }

    if (routeError instanceof Error) {
      Bugsnag.notify(routeError, (event) => {
        event.severity = "error";
      });
    }
  }, [routeError, is404]);

  // If the error is a 404, don't render an error message.
  if (is404) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-bind
  return <ErrorView className="px-4 py-2" clearError={() => navigate(0)} />;
}

interface PageProps {
  build: Build;
  faviconPaths: FaviconPaths;
  waterfallAvailable: boolean;
  isImpersonating: boolean;
}

export default (props: PageProps) => {
  const store = new BuildShowStore(props);
  const router = createBrowserRouter(routes);

  return (
    <BuildContextProvider store={store}>
      <RouterProvider router={router} />
    </BuildContextProvider>
  );
};
