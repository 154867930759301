/* eslint-disable id-length */
import { Outlet, useMatches } from "react-router-dom";
import { useCallback, useRef } from "react";
import PausedClusterQueuesNotice from "app/components/shared/PausedClusterQueuesNotice";
import BuildContentNavigation from "../components/BuildContentNavigation";
import { BuildView } from "..";
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import Header from "app/components/build/Header";
import Sidebar from "../components/Sidebar";
import { PipelineInfo } from "../../Header/PipelineInfo";
import { Branch } from "../../Header/Branch";
import { Commit } from "../../Header/Commit";
import BuildDuration from "../../Duration";
import PipelineStateIcon, {
  VARIANTS,
} from "app/components/shared/PipelineStateIcon/PipelineStateIcon";
import { twMerge } from "tailwind-merge";
import { StatusBar } from "../components/StatusBar";
import { getBuildColor } from "../lib/getBuildColor";
import { BuildActions } from "../components/BuildActions";
import { Trigger } from "../../Header/Trigger";
import BuildMessage from "../../Message";
import BuildNumber from "../../Header/BuildNumber";
import { PullRequest } from "../../Header/PullRequest";
import BuildPageToggleDialog from "../components/BuildPageToggleDialog";
import {
  clampSidebarWidth,
  DockPosition,
  useBuildPreferencesStore,
} from "../lib/useBuildPreferencesStore";
import { ResizeHandle } from "../components/ResizeHandle";
import classNames from "classnames";
import BktecBadge from "app/components/shared/BktecBadge";
import { AnimatePresence } from "framer-motion";
import StepDrawer from "../components/StepDrawer";
import useQueryParams from "../lib/useQueryParams";
import { Revel } from "../components/Revel";
import debounce from "lodash/debounce";

/**
 * Build page layout.
 */
export default function BuildShowLayout() {
  const matches = useMatches();
  const { build, store } = useBuild();

  const { stepUuid, jobUuid } = useQueryParams();
  const stepOrJobId = stepUuid || jobUuid;

  const view = [...matches].pop()?.id as BuildView;
  const theme = getBuildColor(build);

  const widthRef = useRef(useBuildPreferencesStore().sidebarWidth);
  const setWidth = useBuildPreferencesStore((state) => state.setSidebarWidth);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);

  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);

  const debounceSyncWidth = useRef(
    debounce((width: number) => {
      setWidth(width);
    }, 1000),
  ).current;

  const resizeSidebar = useCallback(
    (x: number) => {
      if (!sidebarRef.current || !handleRef.current) {
        return;
      }

      const offset =
        handleRef.current.getBoundingClientRect().left -
        sidebarRef.current.getBoundingClientRect().width;

      const width = clampSidebarWidth(x - offset);
      sidebarRef.current.style.width = `${width}px`;
      debounceSyncWidth(width);
    },
    [setWidth],
  );

  return (
    <>
      <PausedClusterQueuesNotice pausedQueues={build.dispatchPausedClusterQueues} />

      {Features.BuildSidebar ? (
        // Negative top margin to align new build page layout borders
        <div className="flex flex-col gap-2 flex-auto min-w-0 relative -mt-2.5">
          <div className="flex flex-col md:flex-row gap-2 md:items-center">
            <div className="flex flex-wrap items-center gap-2 min-w-0 flex-1">
              <PipelineInfo />

              <div className="flex items-center gap-2 min-w-0 flex-1 basis-[300px] text-sm -ml-2">
                {build.branchName && (
                  <>
                    <span className="text-gray-500 px-2 select-none shrink-0">/</span>

                    <div className="min-w-[52px] max-w-xs">
                      <Branch {...build} />
                    </div>
                  </>
                )}

                {build.commitId && (
                  <>
                    <span className="text-gray-500 px-2 select-none shrink-0">/</span>

                    <div className="shrink-0 flex items-center gap-1">
                      <Commit {...build} />

                      {build.pullRequest && (
                        <span className="shrink-0">
                          (<PullRequest {...build} />)
                        </span>
                      )}
                    </div>
                  </>
                )}

                <span className="text-gray-500 px-2 select-none shrink-0">/</span>

                <div className="flex items-center gap-1 shrink-0">
                  <BuildNumber {...build} />
                </div>
              </div>

              <div className="flex gap-2 shrink-0 md:shrink-1">
                <BuildActions />
              </div>
            </div>
          </div>

          <div className="flex flex-auto flex-row gap-2 min-w-0">
            <div
              className="flex-col gap-4 @container min-w-0 hidden md:!flex"
              style={{ width: `${widthRef.current}px` }}
              ref={sidebarRef}
            >
              <div className="relative h-full">
                <Sidebar />
              </div>
            </div>

            <div
              className={classNames("flex flex-auto gap-2 relative min-w-0", {
                "flex-col": dockPosition === DockPosition.Bottom,
                "flex-row": dockPosition === DockPosition.Right,
              })}
              id="drawer-container"
            >
              <ResizeHandle
                direction="horizontal"
                onResize={resizeSidebar}
                ref={handleRef}
                className="rounded-l-md left-[1px]"
              />

              <div className="flex flex-col flex-auto min-w-0 rounded-md relative">
                <StatusBar className="rounded-t-md" />

                <div
                  className="border-x border-b"
                  style={{
                    backgroundColor: theme.tertiaryColor,
                    borderColor: theme.primaryColor,
                  }}
                >
                  <div className="flex flex-col px-3 pt-3 pb-3">
                    <div className="flex flex-col gap-1">
                      <div className="flex flex-auto min-w-0">
                        <div className="flex-auto flex items-center gap-1 min-w-0">
                          <h3
                            className={twMerge(
                              "max-w-prose text-base m-0",
                              build.message && "truncate",
                            )}
                          >
                            <BuildMessage
                              className="text-inherit focus:text-inherit hover:text-inherit truncate max-w-full"
                              url={build.commitUrl}
                              message={build.message}
                            />
                          </h3>
                        </div>

                        <div className="flex flex-col shrink-0 self-stretch gap-2 pl-4">
                          <div className="flex gap-2 items-center">
                            <div className="rounded-md py-1">
                              <BuildDuration build={build} />
                            </div>

                            <PipelineStateIcon
                              build={build}
                              style={{
                                width: "24px",
                                height: "24px",
                                color: theme.primaryColor,
                              }}
                              variant={VARIANTS.large}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-auto min-w-0 justify-between">
                        <div className="flex gap-x-1 gap-y-1 items-center flex-wrap text-xs">
                          <Trigger {...build} />
                        </div>

                        {build.project.bktecBadgeEnabled && (
                          <BktecBadge
                            buildId={build.id}
                            pipelineHasHistoricalTestPlans={build.project.hasTestPlans}
                            steps={build.steps}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-x border-b border-gray-400 flex flex-col flex-auto min-w-0 rounded-b-md">
                  <div
                    className={twMerge(
                      "flex justify-between px-2 border-b border-gray-400 shadow-sm",
                      Features.BuildSidebarToggle && "pr-2",
                    )}
                  >
                    <BuildContentNavigation />
                    {Features.BuildSidebarToggle && <BuildPageToggleDialog />}
                  </div>

                  <Outlet />
                </div>
              </div>

              <AnimatePresence initial={false}>
                {stepOrJobId && <StepDrawer key={stepOrJobId} stepOrJobId={stepOrJobId} />}
              </AnimatePresence>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Header
            build={build}
            showRebuild={true}
            store={store}
            currentView={view}
            showJobs={view === BuildView.JobList}
          />
          <div
            className={twMerge(
              "flex justify-between mb-4 px-3 border-b border-gray-400",
              Features.BuildSidebar ? "pr-2" : "pr-0",
            )}
          >
            <BuildContentNavigation />
            {Features.BuildSidebarToggle && <BuildPageToggleDialog />}
          </div>

          <Outlet />
        </>
      )}

      <Revel />
    </>
  );
}
