import * as Select from "@radix-ui/react-select";
import { useCallback, useEffect, useState } from "react";

import PipelineStateIcon from "app/components/shared/PipelineStateIcon/PipelineStateIcon";
import Icon from "app/components/shared/Icon";
import { BuildStates } from "app/constants/BuildStates";
import { useTrack } from "app/components/build/Show/lib/useTrack";
import { getBuildColor } from "../Show/lib/getBuildColor";
import cable from "app/lib/cable";
import Indicator from "app/components/shared/Indicator";
import { useBuild } from "../Show/lib/BuildContext";

type BranchBuild = {
  url: string;
  number: number;
  state: BuildStates;
};

type NavigationData = {
  prev_branch_build?: BranchBuild;
  next_branch_build?: BranchBuild;
  latest_branch_build?: BranchBuild;
};

interface Props {
  number: number;
  state: BuildStates;
  branchName: string;
  project: {
    id: string;
  };
}

export default function BuildNumber({ number, state, branchName, project }: Props) {
  const { build } = useBuild();

  const [showIndicator, setShowIndicator] = useState(false);
  const [navigationData, setNavigationData] = useState<NavigationData | null>(null);
  const navigationEndpoint = build.path.replace(/\/builds\/(\d+)?/, `/builds/$1/navigation`);

  const fetchNavigationData = () => {
    fetch(navigationEndpoint)
      .then((response) => response.json())
      .then((json) => setNavigationData(json));
  };

  useEffect(() => {
    // fetch the navigation data on load. Without it the dropdown does not render.
    fetchNavigationData();

    const sub = cable.subscriptions.create(
      { channel: "Pipelines::PipelineChannel", uuid: project.id },
      {
        received({ event, branch, number }) {
          if (event === "build:created") {
            // Show indicator if there's a new build on the current branch
            if (branch === branchName) {
              setShowIndicator(true);

              // update the navigation data each time a new build is created
              fetchNavigationData();
            }
          }
        },
      },
    );

    return () => {
      sub.unsubscribe();
    };
  }, []);

  const urlForBuild = useCallback(
    (buildNumber: number) => {
      return build.path.replace(/\/builds\/(\d+)/, `/builds/${buildNumber}`);
    },
    [build.path],
  );

  const track = useTrack();
  const navigate = useCallback(
    (url) => {
      const latest =
        navigationData &&
        navigationData.latest_branch_build &&
        url.match(new RegExp(`/builds/${navigationData.latest_branch_build.number}`, "i"))
          ? true
          : false;

      track("Open Different Build", {
        latest: latest,
      });

      window.location.href = url;
    },
    [navigationData],
  );

  const handleOpen = useCallback((open: boolean) => {
    if (open) {
      // fetch fresh navigation data on menu open
      fetchNavigationData();
    } else {
      // hide the indicator on menu close
      setShowIndicator(false);
    }
  }, []);

  if (!navigationData || (!navigationData.prev_branch_build && !navigationData.next_branch_build)) {
    return (
      <span className="flex items-center">
        #{number}
        <Icon icon="custom/outline/chevron-down" className="w-5 h-5 text-gray-500" />
      </span>
    );
  }

  return (
    <Select.Root
      value={`${number}`}
      defaultValue={`${number}`}
      onValueChange={navigate}
      onOpenChange={handleOpen}
      disabled={!navigationData.prev_branch_build && !navigationData.next_branch_build}
    >
      <Select.Trigger className="flex items-center rounded-md transition-colors outline-none hover:underline relative">
        <Select.Value />
        <span className="w-5 self-stretch flex items-center justify-center">
          <Icon icon="custom/outline/chevron-down" className="w-5 h-5" />
          {showIndicator && <Indicator className="absolute" />}
        </span>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content className="z-50">
          <Select.Viewport className="bg-white rounded shadow-md">
            {navigationData.prev_branch_build && (
              <Select.Item
                title="Go to previous build on this branch"
                value={urlForBuild(navigationData.prev_branch_build.number)}
                className="px-2 py-1 flex gap-2 justify-between items-center hover:text-purple-600 hover:bg-purple-100 hover:outline-none cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
              >
                <span>Previous</span>
                <span className="flex justify-between items-center gap-1">
                  <Select.ItemText>#{navigationData.prev_branch_build.number}</Select.ItemText>
                  <PipelineStateIcon
                    build={navigationData.prev_branch_build}
                    style={{
                      color: getBuildColor(navigationData.prev_branch_build).primaryColor,
                    }}
                  />
                </span>
              </Select.Item>
            )}

            <Select.Item
              value={`${number}`}
              className="px-2 py-1 flex gap-2 justify-between items-center hover:text-purple-600 hover:bg-purple-100 hover:outline-none cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
            >
              <span>Current</span>
              <span className="flex items-center gap-1">
                <Select.ItemText>#{number}</Select.ItemText>
                <PipelineStateIcon
                  build={{ state }}
                  style={{ color: getBuildColor({ state }).primaryColor }}
                />
              </span>
            </Select.Item>

            {navigationData.next_branch_build &&
              navigationData.next_branch_build.number !==
                (navigationData.latest_branch_build &&
                  navigationData.latest_branch_build.number) && (
                <Select.Item
                  title="Go to next build on this branch"
                  value={urlForBuild(navigationData.next_branch_build.number)}
                  className="px-2 py-1 flex gap-2 justify-between items-center hover:text-purple-600 hover:bg-purple-100 hover:outline-none cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
                >
                  <span>Next</span>
                  <span className="flex items-center gap-1">
                    <Select.ItemText>#{navigationData.next_branch_build.number}</Select.ItemText>
                    <PipelineStateIcon
                      build={navigationData.next_branch_build}
                      style={{
                        color: getBuildColor(navigationData.next_branch_build).primaryColor,
                      }}
                    />
                  </span>
                </Select.Item>
              )}

            {navigationData.latest_branch_build &&
              navigationData.latest_branch_build.number !== number && (
                <Select.Item
                  title="Go to latest build on this branch"
                  value={urlForBuild(navigationData.latest_branch_build.number)}
                  className="px-2 py-1 flex gap-2 justify-between items-center hover:text-purple-600 hover:bg-purple-100 hover:outline-none cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
                >
                  <span>Latest</span>

                  <span className="flex items-center gap-1">
                    <Select.ItemText>#{navigationData.latest_branch_build.number}</Select.ItemText>
                    {showIndicator ? (
                      <span className="w-[14px] flex items-center justify-center">
                        <Indicator animate={false} />
                      </span>
                    ) : (
                      <PipelineStateIcon
                        build={navigationData.latest_branch_build}
                        style={{
                          color: getBuildColor(navigationData.latest_branch_build).primaryColor,
                        }}
                      />
                    )}
                  </span>
                </Select.Item>
              )}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
